<template>
    <section class="pr-3 pt-4">
        <div class="row mx-0">
            <div class="col bg-white br-12 shadow-15 py-3">
                <p class="text-general f-18 f-600">
                    Cupón tradicional
                </p>
                <p class="mt-1 text-general2 f-14 f-400">
                    Este cupón de aplicará siempre y cuando el {{ cupon.destinatario == 1 ? $config.cliente : $config.vendedor }}  cumpla con las condiciones del mismo.
                </p>
            </div>
            <div class="col ml-3 bg-white shadow-15 br-12 h-100 mx-0">
                <div class="row mx-0 py-3">
                    <div class="col border-right">
                        <p class="text-general text-center f-30 br-20 px-3 py-1 mx-1 f-600">
                            {{ cupon.aplica_para }}
                        </p>
                        <p class="text-general text-center f-14">
                            Aplica
                        </p>
                    </div>
                    <div class="col border-right">
                        <p class="text-general text-center f-30 br-20 px-3 py-1 mx-1 f-600">
                            {{ cupon.disponible }}
                        </p>
                        <p class="text-general text-center f-14">
                            Disponibles
                        </p>
                    </div>
                    <div class="col">
                        <p class="text-general text-center f-30 br-20 px-3 py-1 mx-1 f-600">
                            {{ cupon.usado }}
                        </p>
                        <p class="text-general text-center f-14">
                            Tomados por
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col py-3 bg-white br-12 shadow-15">
                <p class="text-general f-18 f-600">
                    Generales
                </p>
                <div class="row mx-0 mt-3">
                    <div class="col-6">
                        <div class="row mx-0">
                            <i class="icon-cupon text-general" />
                            <div class="col">
                                <p class="text-general f-15">Nombre del cupon:</p>
                                <p class="f-14">{{ cupon.nombre }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row mx-0">
                            <div class="col">
                                <p class="text-general f-15">Codigo:</p>
                                <p class="f-14">{{ cupon.codigo }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 my-4">
                        <div class="row mx-0">
                            <i class="icon-message-text-outline text-general f-20" />
                            <div class="col pl-2">
                                <p class="text-general f-15">
                                    Mensaje del cupón
                                </p>
                                <p class="f-14">
                                    {{ cupon.descripcion }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-6 my-3">
                        <div class="row mx-0">
                            <div class="col">
                                <p class="text-general f-15">Tipo:</p>
                                <p class="f-14">{{ cupon.listado === 1 ? 'Listado General' : 'Digitado por Cliente' }}</p>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-6 d-flex">
                        <!-- <img :src="cupon.pais.bandera" class="obj-cover br-50" width="24" height="24" /> -->
                        <i class="icon-cliente f-20 text-general" />
                        <div class="col pl-2">
                            <p class="text-general f-15">
                                País:
                            </p>
                            <p v-if="cupon.pais" class="f-14">
                                {{ cupon.pais.pais }}
                            </p>
                        </div>
                    </div>
                    <div class="col-6 pl-0 d-flex">
                        <i class="icon-warehouse f-20 text-general" />
                        <div class="col pl-1">
                            <p class="f-15 text-general">
                                Centros de distribución:
                            </p>
                            <p v-for="(c,index) in cupon.cedis" :key="index" class="f-14">
                                {{ c.nombre }}
                            </p>
                        </div>
                    </div>
                    <div class="col-8 d-flex mt-3">
                        <i v-if="cupon.descuento_tipo == 1 || cupon.descuento_tipo == 2" class="icon-sale f-20 text-general" />
                        <div v-if="cupon.descuento_tipo == 1" class="col pl-2">
                            <p class="f-15 text-general">
                                Descuento por porcentaje:
                            </p>
                            <p class="f-14">
                                {{ cupon.descuento }}%
                            </p>
                        </div>
                        <div v-if="cupon.descuento_tipo == 2" class="col pl-2">
                            <p class="f-15 text-general">
                                Descuento valor:
                            </p>
                            <p class="f-14">
                                {{ separadorNumero(cupon.descuento) }}
                            </p>
                        </div>
                    </div>
                    <div class="col-6 mt-3 d-flex">
                        <i class="icon-arrow-collapse-horizontal f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Limite de uso de cupón:
                            </p>
                            <p class="f-14">
                                {{ cupon.limite_total || 'Sin limite' }}
                            </p>
                        </div>
                    </div>
                    <div class="col-6 pl-0 mt-3 d-flex">
                        <i class="icon-lock f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Limite de uso por cliente:
                            </p>
                            <p class="f-14">
                                {{ cupon.limite_usuario || 'Sin limite' }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col py-3 bg-white ml-3 br-12 shadow-15">
                <p class="text-general f-600">
                    Condiciones para seleccionar a los {{ cupon.destinatario == 1 ? $config.cliente : $config.vendedor }} automaticos
                </p>
                <div class="row mx-0 mt-3">
                    <div class="col-6 d-flex">
                        <i class="icon-receipt f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Cantidad de pedidos realizados:
                            </p>
                            <p class="f-14">
                                Entre {{ cupon.pedidos_minimo || 0 }} y {{ cupon.pedidos_maximo || 'Sin limite' }} Pedidos
                            </p>
                        </div>
                    </div>
                    <div class="col-6 d-flex">
                        <i class="icon-cake-variant f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Edad:
                            </p>
                            <p class="f-14">
                                Entre {{ cupon.edad_minimo || 0 }} y {{ cupon.edad_maximo || 'Sin limite' }} Años
                            </p>
                        </div>
                    </div>
                    <div class="col-6 mt-3 d-flex">
                        <i class="icon-account f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Genero
                            </p>
                            <p class="f-14">
                                {{ cupon.hombres ? 'Hombres' : '' }} - {{ cupon.mujeres ? 'Mujeres' : '' }} {{ cupon.otros ? ' - Otros' : '' }}
                            </p>
                        </div>
                    </div>
                    <div class="col-6 mt-3 d-flex">
                        <i class="icon-historial f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Antiguedad:
                            </p>
                            <p class="f-14">
                                Entre {{ cupon.antiguedad_minimo || 0 }}  y {{ cupon.antiguedad_maximo || 'Sin limite' }} meses
                            </p>
                        </div>
                    </div>
                    <div class="col-12 mt-3 d-middle">
                        <i class="icon-historial f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Cupones usados:
                            </p>
                            <p class="f-14">
                                Entre {{ cupon.cupones_minimo || 0 }} y {{ cupon.cupones_maximo || 'Sin limite' }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col bg-white shadow-15 br-12 py-3">
                <p class="text-general f-600">
                    Limites del uso del cúpon
                </p>
                <div class="row mx-0">
                    <div class="col-6 mt-3 d-flex">
                        <i class="icon-currency-usd-circle f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Valor mínimo de compra
                            </p>
                            <p class="f-14">
                                {{ separadorNumero(cupon.compra_minima) }}
                            </p>
                        </div>
                    </div>
                    <div class="col-6 mt-3 d-flex">
                        <i class="icon-cash-plus f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Descuento máximo aplicado:
                            </p>
                            <p class="f-14">
                                {{ separadorNumero(cupon.descuento_maximo) }}
                            </p>
                        </div>
                    </div>
                    <div class="col-12 mt-3 d-flex">
                        <i class="icon-calendar-clock f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Vigencia
                            </p>
                            <p class="f-14">
                                Desde: {{ cupon.fecha_inicio | helper-fecha('DD MMMM YYYY hh:mm a') }}
                            </p>
                            <p class="f-14">
                                Hasta: {{ cupon.fecha_fin | helper-fecha('DD MMMM YYYY hh:mm a') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col bg-white shadow-15 ml-3 br-12 py-3">
                <p class="text-general f-600 mb-3">
                    Productos aplicados
                </p>
                <div class="col-12 mb-3 d-middle">
                    <i class="icon-package-variant-closed f-20 text-general" />
                    <div class="col pl-2">
                        <p class="f-15 text-general">
                            {{ cupon.productos_oferta ? 'Incluir' : 'Excluir' }} productos en oferta
                        </p>
                    </div>
                </div>
                <div v-if="cupon.todo_catalogo" class="col-6 mb-3 d-middle">
                    <i class="icon-cart-outline f-20 text-general" />
                    <div class="col pl-2">
                        <p class="f-15 text-general">
                            Aplica todo Catalogo
                        </p>
                    </div>
                </div>
                <div v-else class="col-6 mb-3">
                    <div class="row mx-0 justify-content-end">
                        <div class="col-6">
                            <div class="d-middle">
                                <i class="icon-format-list-bulleted-type f-20 text-general" />
                                <p class="f-15 text-general">
                                    Aplica las categorías:
                                </p>
                            </div>
                            <p v-for="(cat,index) in cupon.categorias" :key="index" class="f-14 pl-2">
                                {{ cat.categoria }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            url_image: 'https://www.eluniversal.com.mx/sites/default/files/2021/05/06/bandera-de-colombia.jpg'
        }
    },
    computed:{
        ...mapGetters({
            cupon: 'cupones/cupones/datosDetalle'
        }),
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        },
    },
}
</script>

<style lang="scss">
.shadow-15{
    box-shadow: 0px 1px 4px #15223214;
}
</style>
